import React from 'react'
import Page from '../components/Page'

const ProductTheftPage = () => (
  <Page title="Monitoring">
    <h1 className="text-2xl text-center w-full mt-8">UNAUTHORIZED SALE OF YOUR PRODUCTS - DISCOVER PRODUCT THEFT AND LOCATE THE SOURCE</h1>
      <p className="mt-8 w-2/3 text-center">
        Have you seen someone sell your products unauthorized?<br/>
        Do these products carry your trade name?<br/>
        Are these products of bad quality?<br/>
        Have these products reached the end of life and are worn out?<br/>
        <br/>
        When competitors copy our customer's products, we will discover the product theft and find competitors' source.<br/>
      </p>
  </Page>
)

export default ProductTheftPage